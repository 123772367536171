import { getHostName } from "../utils";

export async function fetchStages(query, filters, family, type) {
  const jsonString = encodeURIComponent(JSON.stringify(filters));
  const data = await fetch(
    `${getHostName()}/api/stages/?format=json&filter=${jsonString}&query=${query}&family=${family}&type=${type}`
  );
  return await data.json();
}

export async function fetchStageInfo(id, startDate) {
  const data = await fetch(
    `${getHostName()}/api/stageinformation/?id=${id}${
      startDate ? `&startdate=${JSON.stringify(startDate.toISOString())}` : ""
    }&format=json`
  );
  return await data.json();
}
