import { useState, useEffect, useRef } from "react";
import { createTheme } from "@mui/material";

export function changeTimeToLocal(events) {
  return events.map((event) => ({ ...event, time: event.time.slice(0, -1) }));
}

export function formatTime(time) {
  if (time === null) {
    return null;
  }
  const date = new Date(time);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${formattedDate} ${hours}:${minutes} UTC`;
}

export function allValuesTrue(dict) {
  if (typeof dict === "boolean") {
    return dict;
  }
  if (typeof dict === "object" && dict !== null) {
    return Object.values(dict).every((value) => allValuesTrue(value));
  }
}

export function setAllValuesTrue(dict) {
  const newDict = {};
  for (let key in dict) {
    if (typeof dict[key] === "object" && dict[key] !== null) {
      newDict[key] = setAllValuesTrue(dict[key]);
    } else if (typeof dict[key] === "boolean") {
      newDict[key] = true;
    } else {
      newDict[key] = dict[key];
    }
  }
  return newDict;
}

export function toTitleCase(str) {
  if (typeof str === "string") {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  return null;
}

function concatenatedList(items) {
  if (items.length === 1) {
    return items[0];
  } else if (items.length > 1) {
    const lastItem = items.pop();
    const separator = items.length > 1 ? ", and " : " and ";
    return items.join(", ") + separator + lastItem;
  } else {
    return "N/A";
  }
}

export function makeRecoveryString(recoveries, zones) {
  const recoveriesList = recoveries.map((recovery) => {
    if (recovery.landing_zone) {
      const zone = zones.find((zone) => zone.id === recovery.landing_zone);
      return `${zone?.nickname} (${recovery.method_success ? toTitleCase(recovery.method_success) : "TBD"})`;
    } else if (recovery.method) {
      var tempStr = toTitleCase(recovery.method.replace("_", " "));

      if (recovery.method !== "EXPENDED") {
        tempStr = tempStr.concat(" ", `(${recovery.method_success ? toTitleCase(recovery.method_success) : "TBD"})`);
      }

      return tempStr;
    } else {
      return "Expended";
    }
  });

  return concatenatedList(recoveriesList);
}

export function versionFormat(str) {
  const regex = /V(\d)/g;
  if (regex.test(str)) {
    return str.replace(regex, "v$1");
  }
  return str;
}

export function createPath(arr) {
  let str = "";
  for (const item of arr) {
    str += `${item}.`;
  }
  return str;
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Clear the timeout if the value changes before the delay ends
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to update the debounced value
    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
}

export function filterLaunchByTime(data) {
  const now = new Date();
  const previousLaunches = [];
  const futureLaunches = [];

  changeTimeToLocal(data.launches).forEach((launch) => {
    const launchDate = new Date(launch.time);
    if (launchDate < now) {
      previousLaunches.push(launch);
    } else {
      futureLaunches.push(launch);
    }
  });

  const { launches, ...rest } = data; // Exclude the launches list from the original data

  return { ...rest, previousLaunches, futureLaunches };
}

export function getHostName() {
  if (window.location.hostname === "localhost") {
    return "http://127.0.0.1:8000";
  }
  return `https://api.${window.location.hostname}`;
}

export function getPhotoLocation() {
  if (window.location.hostname === "localhost") {
    return "http://127.0.0.1:8000";
  }
  return "";
}

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#4e4ecf",
    },
    background: {
      default: "rgb(31, 41, 55)",
      paper: "rgb(31, 41, 55)",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Ensure label text is always white
          "&.Mui-focused": {
            color: "#ffffff", // Ensure label text remains white when focused
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          "&.MuiTypography-root": {
            color: "#ffffff", // Ensures higher specificity
          },
        },
        overline: {
          color: "#ffffff",
          "&.MuiTypography-overline": {
            color: "#ffffff", // Ensures higher specificity
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#4e4ecf", // Purple border
          },
          "&:hover fieldset": {
            borderColor: "#4e4ecf", // Purple border on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#4e4ecf", // Purple border when focused
          },
          color: "#ffffff", // Text color inside the input
        },
        input: {
          color: "#ffffff", // Ensure input text is white
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Ensure text color is white
          backgroundColor: "#2e2e4e", // Background color for select
          "&:before": {
            borderColor: "#3e3e6e", // Border color before the component is interacted with
          },
          "&:after": {
            borderColor: "#3e3e6e", // Border color after the component is interacted with
          },
        },
        icon: {
          color: "#ffffff", // Ensure the dropdown icon color is white
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: "#ffffff",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          color: "#ffffff",
        },
        weekDayLabel: {
          color: "#ffffff",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#2e2e4e",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #4e4ecf",
        },
        indicator: {
          backgroundColor: "#4e4ecf",
        },
        flexContainer: {
          justifyContent: "center",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: "#ffffff",
        },
        root: {
          fontSize: "1.5rem",
          fontWeight: "bold",
          textTransform: "none",
          minWidth: 72,
          marginRight: (theme) => theme.spacing(4),
          "&.Mui-selected": {
            color: "#ffffff",
            fontWeight: (theme) => theme.typography.fontWeightMedium,
          },
          "&.Mui-focusVisible": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Ensure text color is white
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Ensure menu item text color is white
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
  },
});
