import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts";
import "./DynamicChart.css";

// DarkBarChart Component
export default function DarkBarChart({ seriesInput, xAxisInput, showLegend, chartWidth = 1000, totalLaunches }) {
  // darkTheme is mui default that changes the graph labels to be white instead of black, increasing readability
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  // Update the xAxis input with styling/functions
  const updatedXAxisInput = xAxisInput.map((xAxis) => ({
    ...xAxis,
    // format the value to display the label (launch name) on tooltip hover instead of x value
    valueFormatter: (value, context) => {
      if (context.location === "tooltip") {
        return `${String(value)}: ${totalLaunches[value]} ${totalLaunches[value] === 1 ? "launch" : "launches"}`;
      } else if (context.location === "tick") {
        return `'${String(value).slice(2)}`;
      }
    },
  }));

  // Common properties that the line chart and bar chart will share
  const barChartsParams = {
    xAxis: updatedXAxisInput,
    height: 600,
    width: chartWidth + 40, // x-charts leaves room for the right axis, even if it is null; hence the 40px width increase (and negative margin in CSS)
    rightAxis: null,
    slotProps: {
      legend: {
        direction: chartWidth < 668 ? "column" : "row",
        position: { vertical: "top", horizontal: "middle" },
        padding: 0,
      },
    },
    series: seriesInput,
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chart-container">
        <div className="chart-inner-container">
          <div className="chart" style={{ marginTop: "0" }}>
            <BarChart {...barChartsParams} sx={{ "&&": { touchAction: "auto" } }} />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
