import React, { useState, useEffect, useCallback, useRef } from "react";
import { fetchLaunches } from "../data/launches";
import { fetchRocketFamilies } from "../data/rocketFamilies";
import { fetchOrbits } from "../data/orbits";
import { fetchPads } from "../data/pads.js";
import { fetchZones } from "../data/recoveryZones.js";
import { changeTimeToLocal, setAllValuesTrue, useDebounce } from "../utils";
import Filter from "../multipurpose/Filter.js";
import Card from "../informationCards/InformationCard.js";
import "./LaunchList.css";
import { FilterIcon } from "@heroicons/react/outline";

// Helper Functions
const getQueryParam = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
};

const getPaginationParam = () => {
  const page = getQueryParam("page");
  return Number(page) || 1;
};

const getFilterParam = () => {
  const filterParam = getQueryParam("filter");
  if (filterParam) {
    try {
      return JSON.parse(decodeURIComponent(filterParam));
    } catch (error) {
      console.error("Error parsing filter from URL:", error);
    }
  }
  return null;
};

const LaunchList = () => {
  // State variables
  const [query, setQuery] = useState(getQueryParam("query"));
  const [inputValue, setInputValue] = useState(query);
  const [launches, setLaunches] = useState([]);
  const [filter, setFilter] = useState(getFilterParam());
  const [currentPage, setCurrentPage] = useState(getPaginationParam());
  const [numLaunches, setNumLaunches] = useState(0);
  const [rocketFamilies, setRocketFamilies] = useState([]);
  const [rockets, setRockets] = useState([]);
  const [orbits, setOrbits] = useState([]);
  const [pads, setPads] = useState([]);
  const [zones, setZones] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const launchesPerPage = 25;
  const initLoad = useRef(filter ? false : true);
  const dataLoaded = useRef(false);

  const debouncedFilter = useDebounce(filter, 0);

  // Data mapping
  const objectsData = {
    orbit: orbits,
    rocket__family: rocketFamilies,
    rocket: rockets,
    launch_outcome: ["Success", "Failure", "Partial Failure"],
    pad: pads,
    stageandrecovery__landing_zone: zones,
  };

  const filterNames = {
    launch_outcome: "Outcome",
    rocket__family: "Rocket Family",
    stageandrecovery__landing_zone: "Landing Method",
    orbit: "Orbit",
    pad: "Pad",
    OCEAN_SURFACE: "Ocean",
    stageandrecovery__method_success: "Landing Method Outcome",
  };

  // Function to update filter state
  const updateFilter = useCallback((newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  }, []);

  // Fetch objects for data on page load
  useEffect(() => {
    (async () => {
      const [rocketFamilyData, orbitData, padData, zoneData] = await Promise.all([
        fetchRocketFamilies(),
        fetchOrbits(),
        fetchPads("", {}),
        fetchZones("", {}),
      ]);
      setOrbits(orbitData);
      setPads(padData);
      setZones(zoneData);
      setRocketFamilies(rocketFamilyData);

      const rocketsInData = rocketFamilyData.flatMap((family) =>
        family.rockets.map((rocket) => ({ ...rocket, familyId: family.id }))
      );

      setRockets(rocketsInData);
      dataLoaded.current = true;
    })();
  }, []);

  // Function to fetch initial data
  const fetchInitialFilter = useCallback(async () => {
    if (filter || !dataLoaded.current) {
      return;
    }

    // If the filter was not init by the url, init it
    // Initialize filters
    const initialRocketFamilyFilter = rocketFamilies.reduce((acc, family) => {
      acc[family.id] = { hide__rocket: Object.fromEntries(family.rockets.map((rocket) => [rocket.id, true])) };
      return acc;
    }, {});

    const initialOrbitFilters = Object.fromEntries(orbits.map((orbit) => [orbit.id, true]));
    const initialPadFilters = Object.fromEntries(pads.map((pad) => [pad.id, true]));

    const stageandrecovery__landing_zone = {
      ASDS: { hide__stageandrecovery__landing_zone: {} },
      RTLS: { hide__stageandrecovery__landing_zone: {} },
    };

    const hide__stageandrecovery__method = {
      OCEAN_SURFACE: true,
      PARACHUTE: true,
      expended: true,
    };

    zones.forEach((zone) => {
      if (zone.type === "DRONE_SHIP") {
        stageandrecovery__landing_zone.ASDS.hide__stageandrecovery__landing_zone[zone.id] = true;
      } else if (zone.type === "GROUND_PAD") {
        stageandrecovery__landing_zone.RTLS.hide__stageandrecovery__landing_zone[zone.id] = true;
      }
    });
    setFilter({
      rocket__family: initialRocketFamilyFilter,
      pad: initialPadFilters,
      stageandrecovery__landing_zone: stageandrecovery__landing_zone,
      hide__stageandrecovery__method: hide__stageandrecovery__method,
      stageandrecovery__method_success: {
        SUCCESS: true,
        FAILURE: true,
        PRECLUDED: true,
      },
      launch_outcome: {
        Success: true,
        Failure: true,
        "Partial Failure": true,
      },
      orbit: initialOrbitFilters,
    });
  }, [dataLoaded, filter, orbits, pads, rocketFamilies, zones]);

  // Fetch initial data on component mount
  useEffect(() => {
    fetchInitialFilter();
  }, [fetchInitialFilter, dataLoaded]);

  // Fetch and update launches on filter or query change
  useEffect(() => {
    const updateURL = () => {
      const params = new URLSearchParams(window.location.search);
      params.set("filter", encodeURIComponent(JSON.stringify(debouncedFilter)));
      params.set("page", currentPage);
      params.set("query", query);
      window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    };

    const fetchData = async () => {
      if (debouncedFilter && initLoad.current) {
        initLoad.current = false;
        return;
      }
      setLoading(true);
      updateURL();
      const data = await fetchLaunches(query, debouncedFilter, currentPage);
      setNumLaunches(data.count);
      setLaunches(changeTimeToLocal(data.results));
      setLoading(false);
    };

    fetchData();
  }, [query, debouncedFilter, currentPage]);

  // Calculate total number of pages
  const totalPages = Math.ceil(numLaunches / launchesPerPage);

  // Handler for page changes
  const handlePageChange = useCallback(
    (delta) => {
      setCurrentPage((prev) => Math.max(1, Math.min(prev + delta, totalPages)));
    },
    [totalPages]
  );

  // Handler for search
  const handleSearch = useCallback(() => {
    setQuery(inputValue);
    setCurrentPage(1);
  }, [inputValue]);

  // Handler for Enter key in search input
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") handleSearch();
    },
    [handleSearch]
  );

  // Toggle filter visibility
  const toggleFilterVisibility = useCallback(() => {
    setIsFilterVisible((prev) => !prev);
  }, []);

  // Reset filters and search query
  const resetFilters = () => {
    const newFilter = setAllValuesTrue(filter);
    setFilter(newFilter);
    setQuery("");
    setInputValue("");
    setCurrentPage(1);
  };

  return (
    <div className="max-w-6xl mx-auto page-container">
      <div className="left-container">
        <div className="filter-container">
          <input
            type="text"
            name="q"
            placeholder="Search"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className="search-input"
          />
          <button onClick={handleSearch} className="search-button" style={{ marginRight: "5px" }}>
            Search
          </button>
          <button onClick={resetFilters} className="clear-button">
            Clear
          </button>
        </div>
        <div className="pagination-container">
          <h2 className="text-xl mb-2 bold">
            Page {currentPage} of {totalPages}
          </h2>
          <div className="pagination-buttons-filter">
            <div className="pagination-buttons">
              <button
                className="pagination-button"
                onClick={() => handlePageChange(-currentPage + 1)}
                disabled={currentPage === 1}
              >
                «
              </button>
              <button className="pagination-button" onClick={() => handlePageChange(-1)} disabled={currentPage === 1}>
                ‹
              </button>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(1)}
                disabled={currentPage >= totalPages}
              >
                ›
              </button>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(totalPages - currentPage)}
                disabled={currentPage >= totalPages}
              >
                »
              </button>
            </div>
            <button className="filter-toggle-button" onClick={toggleFilterVisibility}>
              <FilterIcon className="h-6 w-6 text-gray-300" />
            </button>
          </div>
        </div>
        <div className="filter-width lg:w-1/4 lg:mr-4 lg:mt-0">
          <Filter
            filter={filter}
            updateFilter={updateFilter}
            filterNames={filterNames}
            objects={objectsData}
            isVisible={isFilterVisible}
          />
        </div>
      </div>
      <div className="launch-container">
        <div className="launch-cards-container">
          {loading ? (
            <div className="loading-indicator"></div>
          ) : launches.length > 0 ? (
            launches.map((launch) => (
              <Card
                key={launch.id}
                object={launch}
                objectType="launch"
                subObjects={{ rockets, zones, pads }}
                additionalInfo={{}}
              />
            ))
          ) : (
            <p>No launches are available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaunchList;
