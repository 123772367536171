import { getHostName } from "../utils";

export async function fetchLaunches(query, filter, page) {
  const jsonString = encodeURIComponent(JSON.stringify(filter));
  const data = await fetch(
    `${getHostName()}/api/launches/?page=${page}&format=json&filter=${jsonString}&query=${query}`
  );
  return await data.json();
}

export async function fetchLaunchesOnly(filter) {
  const jsonString = encodeURIComponent(JSON.stringify(filter));
  const data = await fetch(`${getHostName()}/api/launchesonly/?format=json&filter=${jsonString}`);
  return await data.json();
}

export async function fetchLaunchInfo(id) {
  const data = await fetch(`${getHostName()}/api/launchinfo/?id=${id}&format=json`);
  return await data.json();
}
