import React from "react";
const CustomLegend = ({ legendItems }) => {
  return (
    <div className="custom-legend">
      {legendItems.map((item, index) => (
        <div key={index} className="legend-item">
          <div className="legend-color" style={{ backgroundColor: item.color }} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
