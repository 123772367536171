import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import "./DynamicChart.css";
import dayjs from "dayjs";
import { customTheme } from "../utils";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl } from "@mui/material";

const Stats = ({ enteredStats }) => {
  if (enteredStats === null || enteredStats === undefined || typeof enteredStats !== "object") {
    return <div>Invalid stats data</div>;
  }
  const statEntries = Object.entries(enteredStats);
  const statGroups = [];
  for (let i = 0; i < statEntries.length; i += 2) {
    statGroups.push(statEntries.slice(i, i + 2));
  }

  return (
    <div className="stats">
      {statGroups.map((group, index) => (
        <div key={index} className="stats-group">
          {group.map(([key, value]) => (
            <span key={key}>
              <strong>{key}:</strong> {value}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};

export default function SwitchSeriesType({
  seriesInput,
  xAxisInput,
  xAxisLabels,
  showxAxis,
  showAdditionalInfo,
  pastLaunchCount,
  displayStats,
  legend,
  chartWidth = 1000,
  onDateChange,
  selectedDate,
  startDate,
}) {
  /*
 seriesInput: obj; takes in data for series with styling. Note: some data, such as color, may be overridden by the colormap impemented below
 xAxisInput: obj; takes data to display on X axis and styling; note: the data is dummy for launches since the X axis is not displayed
 xAxisLabels: list; is list of names to display in hover over
 showxAxis: bool; determines of the bottom axis should be shown
 showAdditionalInfo: bool; determines if switch button, and stats are shown
 pastLaunchCount: int; used to determine color of launches
 displayStats: obj; dict of stats
 legend: legend obj; legend to display under graph
 chartWidth: passed in as the width of the parent div; customized to show wider on mobile
 onDateChange: function; handle updating of date change of parent component
 selectedDate: dateTime; currently seleceted date on calendar
 startDate: datetIme; earliest selectable calendar date
 */

  const [chartType, setChartType] = useState("line");
  let colorSet = false;

  // darkTheme is mui default that changes the graph labels to be white instead of black, increasing readability
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // Update the xAxis input with styling/functions
  const updatedXAxisInput = xAxisInput.map((xAxis) => {
    let updatedXAxis = {
      ...xAxis,
      // format the value to display the label (launch name) on tooltip hover instead of x value
      valueFormatter: (value, context) => {
        if (context.location === "tooltip" && xAxisLabels && xAxisLabels[value]) {
          return xAxisLabels[value];
        } else if (context.location === "tick") {
          return `'${String(value).slice(2)}`;
        }
        return value;
      },
    };

    // as of mui v7.11, the colormap feature has a bug where lines are not displayed between points if the threshold is above
    // the number of objects; hence, color management is handled differently if this is the case.
    if (xAxis["data"].length > pastLaunchCount) {
      colorSet = true;
      updatedXAxis["colorMap"] = {
        type: "piecewise",
        thresholds: [pastLaunchCount],
        colors: ["#E890FE", "#00CD9E"],
      };
    }

    return updatedXAxis;
  });

  // Update series to show marker (and potentially add color)
  const updatedSeries = seriesInput.map((series) => {
    let updatedSeriesItem = {
      ...series,
      marker: {
        enabled: true,
        radius: 4,
      },
    };

    // If the color not set by the color map, set color
    if (!colorSet && !updatedSeriesItem["color"]) {
      updatedSeriesItem["color"] = "#E890FE";
    }

    return updatedSeriesItem;
  });

  // Common properties that the line chart and bar chart will share
  const chartCommonProps = {
    xAxis: updatedXAxisInput,
    height: 600,
    width: chartWidth + 40, // x-charts leaves room for the right axis, even if it is null; hence the 40px width increase (and negative margin in CSS)
    rightAxis: null,
    slotProps: { legend: { hidden: true } },
    series: updatedSeries,
  };

  if (!showxAxis) {
    chartCommonProps["bottomAxis"] = null;
  }

  // Add any custom parameters for the bar chart here
  const barChartsParams = {
    ...chartCommonProps,
  };

  // Add any custom parameters for the line chart here
  const lineChartsParams = {
    ...chartCommonProps,
  };

  const handleDateChange = (newDate) => {
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chart-container">
        <div className="chart-inner-container">
          <div className="chart" style={showxAxis ? { marginBottom: "-10px" } : {}}>
            {chartType === "bar" && <BarChart {...barChartsParams} sx={{ "&&": { touchAction: "auto" } }} />}
            {chartType === "line" && <LineChart {...lineChartsParams} sx={{ "&&": { touchAction: "auto" } }} />}
          </div>
          {showAdditionalInfo && (
            <div className="controls">
              <ThemeProvider theme={customTheme}>
                <Button variant="contained" onClick={() => setChartType(chartType === "bar" ? "line" : "bar")}>
                  {chartType === "bar" ? "Switch to Line" : "Switch to Bar"}
                </Button>
              </ThemeProvider>
              <div className="date-picker" style={{ marginTop: "0", marginBottom: "0" }}>
                <ThemeProvider theme={customTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      sx={{ width: "150px" }}
                      className="function-selector"
                    >
                      <DatePicker
                        id="start-date-picker"
                        minDate={dayjs(startDate)}
                        maxDate={dayjs(new Date())}
                        value={dayjs(selectedDate)}
                        onChange={handleDateChange}
                        label="Start Date"
                      />
                    </FormControl>
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
              {legend}
              {displayStats ? (
                <div className="stats">
                  <Stats enteredStats={displayStats} />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}
