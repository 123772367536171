import React, { useEffect, useState, useRef, useReducer } from "react";
import { customTheme, toTitleCase } from "../utils.js";
import SwitchSeriesType from "../graphComponents/DynamicChart.js";
import CustomLegend from "../multipurpose/CustomLegend.js";
import { ThemeProvider, Box, Tabs, Tab, Select, MenuItem } from "@mui/material";
import StatCard from "../informationCards/StatCard.js";
import Card from "../informationCards/InformationCard.js";
import { fetchFamilyInfo, fetchRockets } from "../data/rocketFamilies.js";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: "0" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

const FamilyInformation = () => {
  const { family } = useParams();
  const [launchYears, setLaunchYears] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [legendItems, setLegendItems] = useState([]);
  const [stats, setStats] = useState({});
  const [childrenStats, setChildrenStats] = useState({});
  const [rockets, setRockets] = useState([]);
  const [mostFlownBoosters, setMostFlownBoosters] = useState([]);
  const [mostFlownSecondStages, setMostFlownSecondStages] = useState([]);
  const [quickestTurnaroundBooster, setQuickestTurnaroundBooster] = useState(null);
  const [quickestBoosterTurnaround, setQuickestBoosterTurnaround] = useState("");
  const [quickestTurnaroundSecondStage, setQuickestTurnaroundSecondStage] = useState(null);
  const [quickestSecondStageTurnaround, setQuickestSecondStageTurnaround] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [chosenDate, setChosenDate] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const chartRef = useRef(null); // Ref to access the chart container

  // ensures responsive behavior of window
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const colors = ["#E890FE", "#00CD9E", "#eb347d", "#fcba03"];
    const fetchData = async () => {
      let colorIndex = 0;
      const [data, rocketData] = await Promise.all([fetchFamilyInfo(family, chosenDate), fetchRockets()]);

      let legendItemData = [];
      const seriesList = Object.entries(data["series_data"]).map(([key, value]) => {
        const seriesObject = {
          id: key,
          data: value,
          label: key,
          area: false,
          color: colors[colorIndex],
        };
        legendItemData.push({ color: colors[colorIndex], label: key });
        colorIndex = colorIndex + 1;
        return seriesObject;
      });

      setRockets(rocketData);
      setLaunchYears(data["launch_years"]);
      setSeriesData(seriesList);
      setLegendItems(legendItemData);
      setStats(data["stats"]);
      setChildrenStats(data["children_stats"]);
      setMostFlownBoosters(data["boosters_with_most_flights"]);
      setMostFlownSecondStages(data["stage_two_with_most_flights"]);
      setQuickestTurnaroundBooster(data["booster_with_quickest_turnaround"]);
      setQuickestBoosterTurnaround(data["booster_turnaround_time"]);
      setQuickestTurnaroundSecondStage(data["stage_two_with_quickest_turnaround"]);
      setQuickestSecondStageTurnaround(data["stage_two_turnaround_time"]);
      setStartDate(data["start_date"].slice(0, -1));

      if (chosenDate === null) {
        setChosenDate(new Date(data["start_date"].slice(0, -1)));
      }
    };
    fetchData();
  }, [family, chosenDate]);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const parsedChildrenStats = Object.fromEntries(
    Object.entries(childrenStats).map(([rocketName, stats]) => [rocketName, JSON.parse(stats.replace(/'/g, '"'))])
  );

  const handleDateChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isValid()) {
      setChosenDate(date.toDate());
    }
  };

  return (
    <div className="mx-auto graph-container">
      <div style={{ width: "100%" }}>
        <h2 className="centered-heading">{toTitleCase(family)} Reuse Objects</h2>
        <div className="chart-and-stats" ref={chartRef}>
          <SwitchSeriesType
            seriesInput={seriesData}
            xAxisInput={[{ data: launchYears, scaleType: "band" }]}
            xAxisLabels={undefined}
            showxAxis={true}
            showAdditionalInfo={true}
            pastLaunchCount={launchYears.length || 0}
            displayStats={null}
            legend={<CustomLegend legendItems={legendItems} />}
            chartWidth={chartRef.current?.offsetWidth || 1000}
            onDateChange={handleDateChange}
            selectedDate={chosenDate}
            startDate={startDate}
          />
        </div>
      </div>
      <h2 className="centered-heading" style={{ margin: "60px 0 0 0" }}>
        Family Information
      </h2>
      <div className="stat-cards-container">
        {Object.entries(stats).map(([key, value]) => (
          <StatCard title={key} value={value} key={key} />
        ))}
      </div>
      {mostFlownBoosters.length > 0 ? (
        <>
          <h2 className="subheading" style={{ margin: "30px 0 0 0" }}>
            {mostFlownBoosters.length > 2 ? "Most Flown Boosters" : "Most Flown Booster"}
          </h2>
          <div className="launch-container" style={{ width: "80%" }}>
            {mostFlownBoosters.map((booster) => (
              <Card
                key={booster.id}
                object={booster}
                objectType={"stage"}
                subObjects={{ rockets }}
                additionalInfo={{}}
              />
            ))}
          </div>
        </>
      ) : null}

      {quickestTurnaroundBooster ? (
        <>
          <h2 className="subheading">Quickest Booster Turnaround</h2>
          <div className="launch-container" style={{ width: "80%" }}>
            <Card
              key={`${quickestTurnaroundBooster.id} fastest`}
              object={quickestTurnaroundBooster}
              objectType={"stage"}
              subObjects={{ rockets }}
              additionalInfo={{ "Fastest Turnaround: ": quickestBoosterTurnaround }}
            />
          </div>
        </>
      ) : null}

      {mostFlownSecondStages ? (
        <>
          <h2 className="subheading" style={{ margin: "30px 0 0 0" }}>
            {mostFlownSecondStages.length > 2 ? "Most Flown Second Stages" : "Most Flown Second Stage"}
          </h2>
          <div className="launch-container" style={{ width: "80%" }}>
            {mostFlownSecondStages.map((stage) => (
              <Card key={stage.id} object={stage} objectType={"stage"} subObjects={{ rockets }} additionalInfo={{}} />
            ))}
          </div>
        </>
      ) : null}

      {quickestTurnaroundSecondStage ? (
        <>
          <h2 className="subheading">Quickest Second Stage Turnaround</h2>
          <div className="launch-container" style={{ width: "80%" }}>
            <Card
              key={`${quickestTurnaroundSecondStage.id} fastest`}
              object={quickestTurnaroundSecondStage}
              objectType={"stage"}
              subObjects={{ rockets }}
              additionalInfo={{ "Fastest Turnaround: ": quickestSecondStageTurnaround }}
            />
          </div>
        </>
      ) : null}

      <div style={{ width: "80%", margin: "30px 0 0 0" }}>
        <ThemeProvider theme={customTheme}>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            {isMobileView ? (
              <Select
                value={tabValue}
                onChange={(event) => handleTabChange(null, event.target.value)}
                fullWidth
                style={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}
              >
                {Object.keys(parsedChildrenStats).map((rocketName, index) => (
                  <MenuItem value={index} key={index}>
                    {rocketName}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="rocket stats tabs">
                {Object.keys(parsedChildrenStats).map((rocketName, index) => (
                  <Tab label={rocketName} key={index} />
                ))}
              </Tabs>
            )}
          </Box>
          {Object.entries(parsedChildrenStats).map(([rocketName, stats], index) => (
            <TabPanel value={tabValue} index={index} key={index}>
              <div className="stat-cards-container" style={{ width: "100%", paddingTop: "10px" }}>
                {Object.entries(stats).map(([key, value]) => (
                  <StatCard title={key} value={value} key={key} />
                ))}
              </div>
            </TabPanel>
          ))}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default FamilyInformation;
