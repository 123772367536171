import React, { useState, useCallback } from "react";
import { allValuesTrue, toTitleCase, versionFormat, createPath } from "../utils";
import "./Filter.css";

const Filter = ({ filter, updateFilter, filterNames, objects, isVisible, key }) => {
  /*This function returns a filter given a filter dict. The dict is of the following structure:
	{rocket__family: {id: {rocket: {id1: true, id2: true, ...}, otherfilter: {blah}}, id2: {}}, orbit: {id: true, ...}}
	
	This function is run recursively, so it builds the filter until it gets to the lowest level in each branch of the tree
	
	updateFilter is passed through since some states are not stored here (such as pagination that may need to be changed)
	
	filterNames is a dict that has the back end name as a key and the front end name as a value; this allows for the front
	end name to be configured since it may not be consistent from backend name
	
	objects holds all objects for the IDs being passed in -- this is a dict. Allows for accessing of object's attributes
	
	if a var name starts with 'hide__' the label is not rendered and instead only its children are rendered*/
  const [showAllStates, setShowAllStates] = useState({});

  const numItemsToShow = 4;

  const updateChildren = useCallback((obj, value) => {
    if (typeof obj === "object" && obj !== null) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          updateChildren(obj[key], value);
        } else if (typeof obj[key] === "boolean") {
          obj[key] = value;
        }
      }
    }
  }, []);

  const handleUpdateFilter = useCallback(
    (keys, isParent = false) => {
      const newFilter = { ...filter };
      let current = newFilter;

      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = { ...current[keys[i]] };
        current = current[keys[i]];
      }

      const lastKey = keys[keys.length - 1];
      if (typeof current[lastKey] === "object" && isParent) {
        const isParentChecked = !allValuesTrue(current[lastKey]);
        updateChildren(current[lastKey], isParentChecked);
      } else {
        current[lastKey] = !current[lastKey];
      }
      updateFilter(newFilter);

      const params = new URLSearchParams(window.location.search);
      params.set("filter", encodeURIComponent(JSON.stringify(newFilter)));
      window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    },
    [filter, updateChildren, updateFilter]
  );

  const toggleShowAll = (key) => {
    setShowAllStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderFilters = (filtersObj, currentKey, isTopLevel = false) => {
    let renderedCount = 0; // renderedCount doesn't allow for more than three objects to be rendered; if more the "more" icon appears"

    if (!filtersObj || typeof filtersObj !== "object") {
      // Safety check added here
      return null;
    }

    const filterElements = Object.keys(filtersObj).map((key) => {
      const filter = filtersObj[key];
      const newKey = currentKey ? [...currentKey, key] : [key];

      let filterObject = undefined;
      if (newKey.length > 1) {
        const keyPart = newKey[newKey.length - 2].replace("hide__", "");
        filterObject = (objects[keyPart] || []).find((obj) => String(obj.id) === key);
        // Get the object the filter references; this allows for its attributes to be called
      }

      if (!isVisible) {
        return null;
      }

      if (key.startsWith("hide__")) {
        return (
          <div key={createPath(newKey)} className="ml-6 subtypes-container">
            {renderFilters(filter, newKey)}
          </div>
        );
      }

      if (typeof filter === "boolean") {
        // If the level of the filter holds a boolean, it is the lowest level in the tree. Else we know it has children
        renderedCount++;
        if (!showAllStates[currentKey] && renderedCount > numItemsToShow) return null; // Return null to limit to three options

        return (
          <label key={createPath(newKey)} className="flex items-center">
            <input
              type="checkbox"
              checked={filter}
              onChange={() => handleUpdateFilter(newKey)}
              className="h-5 w-5 bg-gray-800 border-gray-700 rounded focus:ring-0"
              style={{ backgroundColor: filter ? filterObject?.color : undefined }}
              // Sets the background color to the value stored in the database if it exists
            />
            <span>
              {filterObject?.nickname || filterObject?.name || versionFormat(toTitleCase(key.replace("_", " ")))}
            </span>
          </label>
        );
      } else {
        const allChildrenChecked = allValuesTrue(filter);
        const isLabelOnly = filterNames[key] !== undefined;
        // If the key doesn't exist, assumed label should not be shown

        renderedCount++;
        if (!showAllStates[currentKey] && renderedCount > numItemsToShow) return null;

        return (
          <div key={createPath(newKey)} className="mb-2 family-container">
            {isLabelOnly ? ( // isLabel ensures that no checkbox is rendered; only label
              <span className="flex items-center label">Filter by {filterNames[key]}</span>
            ) : (
              <label className="flex items-center family-label">
                <input
                  type="checkbox"
                  checked={allChildrenChecked}
                  onChange={() => handleUpdateFilter(newKey, true)}
                  className="h-5 w-5 bg-gray-800 border-gray-700 rounded focus:ring-0"
                  style={{ backgroundColor: filterObject?.color }}
                />
                <span>{filterObject?.nickname || filterObject?.name || key}</span>
              </label>
            )}
            <div className={`ml-6 subtypes-container ${isTopLevel ? "ml-0" : "ml-6"}`}>
              {renderFilters(filter, newKey)}
            </div>
          </div>
        );
      }
    });

    // If there are more than three filters, hide the rest behind a show more/less button
    if (!showAllStates[currentKey] && renderedCount > numItemsToShow) {
      filterElements.push(
        <button key="showMoreButton" className="text-gray-600 mt-2 ml-6" onClick={() => toggleShowAll(currentKey)}>
          Show More
        </button>
      );
    } else if (showAllStates[currentKey] && renderedCount > numItemsToShow) {
      filterElements.push(
        <button key="showLessButton" className="text-gray-600 mt-2 ml-6" onClick={() => toggleShowAll(currentKey)}>
          Show Less
        </button>
      );
    }

    return filterElements;
  };

  return <div className="filter">{filter && renderFilters(filter, key, true)}</div>;
};

export default Filter;
